export const colors = {
  gray: "#5f5f5f",
  lightGray: "#cbcbcb",
  darkGray: "#2d2d2d",
  nearBlack: "#050505",
  action: "#ff902f",
  selected: "#afacac",
  pureWhite: "#ffffff",
  lighterColor: "rgba(255,255,255,0.2)",
  darkerColor: "rgba(0,0,0,0.3)",
};

export const tagsColor = [
  "#33970c",
  "#831422",
  "#10467d",
  "#f0a74f",
  "#cc86da",
  "#08630c",
  "#300d0d",
  "#0a1e31",
  "#4d3212",
  "#46082e",
  "#0893e4",
  "#a7cdf4",
  "#0cbdba",
  "#c0f5cc",
  "#c50505",
  "#07c7f7",
  "#5b0591",
  "#cc9b1f",
  "#e8f52e",
  "#ffff00",
];
