export const LAYOUT_MODE = {
  full_untagged: "full_untagged",
  half: "half",
  full_tagged: "full_tagged",
};

export const SECTION_MODE = {
  min: "min",
  half: "half",
  max: "max",
};
